import styles from "@/styles/Home.module.scss";
import Image from "next/image";
import Link from "next/link";
import { Col, Container, Nav, Row, Tab, Table, Tabs } from "react-bootstrap";
import { transformation, useCase, whyChoose } from "@/data/home";
import {
  Transformations,
  useCaseProps,
  whyListProps,
  whyProps,
} from "@/utils/types";
import dynamic from "next/dynamic";
import { useEffect, useLayoutEffect, useState } from "react";
import useWindowSize from "@/hooks/useWindowSize";
import { structuredData } from "@/data/structuredData";

const Layout = dynamic(() => import("@/components/Layout"));
const StructuredData = dynamic(() => import("@/components/StructuredData"));
const Contact = dynamic(() => import("@/components/Contact"));
const Testimonial = dynamic(() => import("@/components/Testimonial"));
const BusinessMarquee = dynamic(() => import("@/components/BusinessMarquee"));
const Transformation = dynamic(
  () => import("@/components/list/Transformation")
);
const UseCase = dynamic(() => import("@/components/list/UseCase"));

const Home = () => {
  const [change, setChange] = useState<any>("");
  const [left, setLeft] = useState<number>(0);
  const [width] = useWindowSize();

  const currentDate = new Date();
  const cutoffDate = new Date('January 1, 2025 23:59:00');

  useLayoutEffect(() => {
    updateSize();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const updateSize = () => {
    const element = document.getElementById("choose");
    if (!element) return;

    setLeft(window.screen.width - element.offsetWidth);
  };

  return (
    <Layout
      title="Custom Software Development & Digital Transformation Services - Cubet"
      description="Cubet: Your Digital Transformation Partner. We offer Custom Software Development + AI solutions for businesses, ensuring exceptional experiences on mobile, cloud, and web platforms."
      ogTitle="Custom Software Development Company | Cubet"
      ogImage={`${process.env.NEXT_PUBLIC_BASE_URL}/assets/images/home_top_latest.png`}
      footerType="banner"
      banner={true}
      bannerClass="home-banner"
    >
      <StructuredData data={[structuredData.home1, structuredData.home2]} />
      <div className={styles.home}>
        <div className="header-section">
          <Container>
            <Row className="align-items-center justify-content-between">
              <Col lg={6}>
                <h1 className="mb-4 mb-2-rem mt-md-5 mt-lg-0">
                  The Experience We <br className="d-break-new" />{" "}
                  <span>Create with Technology</span>{" "}
                  <br className="d-break-new" /> is <span>Everything!</span>
                </h1>
                <p className="mb-5 text-home">
                  Custom Software Development + AI and Data solutions to build
                  exceptional experiences <br className="d-break-new" /> that
                  can take your business where it needs to go.
                </p>
                <p className="d-inline-block">
                  <Link prefetch={false} href="/contact-us">
                    <span>Talk to Us</span>
                    <span>Contact Now</span>
                    <span></span>
                  </Link>
                </p>
              </Col>
              <Col>
                <div className="text-md-end">
                  <Image
                    src="/assets/images/home_top_latest.png"
                    width={620}
                    height={655}
                    priority={true}
                    alt="The Experience we create with Technology is Everything!"
                    style={{ objectFit: "contain" }}
                    className="img-fluid hero-img border-radius-50"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="progresses-container">
          <Container className="new-container">
            <div className="progresses">
              <Container>
                <Row className="align-items-center justify-content-between">
                  {/* <div className="d-flex align-items-center partner-logo d-block d-xl-none">
                      <Image
                        src="/assets/logo/microsoft-6.svg"
                        width={141}
                        height={39}
                        priority={false}
                        alt="microsoft"
                        style={{ objectFit: "contain" }}
                        className="img-fluid progress-img"
                      />
                      <Image
                        src="/assets/logo/laravel-partner.svg"
                        width={145}
                        height={51}
                        priority={false}
                        alt="laravel"
                        style={{ objectFit: "contain" }}
                        className="img-fluid progress-img"
                      />
                      <Image
                        src="/assets/logo/ionos-logo.svg"
                        width={120}
                        height={25}
                        priority={false}
                        alt="laravel"
                        style={{ objectFit: "contain" }}
                        className="img-fluid logo-mt-3" 
                      />
                    </div> */}
                  <Col lg={7} xl={7}>
                    <div className="d-flex partner-logo">
                      <img
                        src="/assets/logo/microsoft-partner-last.png"
                        // width={0}
                        height={50}
                        // priority={false}
                        alt="microsoft"
                        style={{ objectFit: "contain" }}
                        className="progress-img"
                      />
                      <img
                        src="/assets/logo/laravel-partner.png"
                        // width={0}
                        height={50}
                        // priority={false}
                        alt="laravel"
                        style={{ objectFit: "contain" }}
                        className="progress-img"
                      />
                      <img
                        src="/assets/logo/ionos-partner.png"
                        // width={0}
                        height={50}
                        // priority={false}
                        alt="laravel"
                        style={{ objectFit: "contain" }}
                        className="logo-mt-3"
                      />
                    </div>
                    <p className="h2 mb-4">
                      Driving Progress, <br className="d-break-new" /> Improving
                      Lives
                    </p>
                    <p className="normal-text line-height mb-5 mb-lg-0">
                      Cubet is a full-service digital solutions and consulting
                      company that <br className="d-break-new" /> offers
                      comprehensive software development services and advanced{" "}
                      <br className="d-break-new" /> cognitive solutions for
                      micro to large enterprises. We help businesses{" "}
                      <br className="d-break-new" /> become smart and
                      future-proof through our AI and data-driven solutions{" "}
                      <br className="d-break-new" /> for mobile, cloud, and web
                      platforms.
                    </p>
                  </Col>
                  <Col
                    lg={5}
                    xl={5}
                    className="d-md-grid d-lg-block justify-content-center"
                  >
                    <Table bordered className="cross">
                      <tbody>
                        <tr>
                          <td>
                            <h2>15+</h2>
                            <p>Years in Business</p>
                          </td>
                          <td>
                            <div>
                              <h2>150+</h2>
                              <p>Team Size</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h2>700+</h2>
                            <p>Completed Projects</p>
                          </td>
                          <td>
                            <div>
                              <h2>200+</h2>
                              <p>Happy Clients</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Link prefetch={false} href="/resources/case-studies">
                      View how the company helped business around the world
                    </Link>
                  </Col>
                </Row>
              </Container>
            </div>
          </Container>
        </div>
        <BusinessMarquee />
        <div className="transformation">
          <Tab.Container defaultActiveKey="0">
            <Container
              style={
                width > 767
                  ? {
                    marginRight: 0,
                    paddingLeft: left / 2,
                    maxWidth: left ? "100%" : "",
                  }
                  : {}
              }
            >
              <Row>
                <Col lg={5}>
                  <h2 className="heading">Our Transformation Services</h2>
                  <Nav
                    variant="pills"
                    className="flex-column"
                    onSelect={(e) => setChange(e)}
                  >
                    {Object.keys(transformation).map(
                      (item: string, index: number) => (
                        <Nav.Item key={index.toString()}>
                          <Nav.Link eventKey={index.toString()}>
                            {item}
                          </Nav.Link>
                        </Nav.Item>
                      )
                    )}
                  </Nav>
                </Col>
                <Col lg={7}>
                  <Tab.Content>
                    {Object.keys(transformation).map(
                      (item: keyof Transformations, index: number) => (
                        <Transformation
                          data={transformation[item]}
                          tab={index.toString()}
                          change={change}
                          key={index.toString()}
                        />
                      )
                    )}
                  </Tab.Content>
                </Col>
              </Row>
            </Container>
          </Tab.Container>
        </div>
        <div className="choose">
          <Container id="choose">
            <Row>
              <Col lg={7}>
                <h2 className="heading">Why Choose Us?</h2>
                <p className="normal-text">
                  We see ourselves as a trusted technology partner committed to
                  delivering customised solutions and{" "}
                  <br className="d-break-new" /> uncompromising quality. Our
                  approach and strategies have been refined over 15 years of
                  experience <br className="d-break-new" /> building solutions
                  for a diverse customer base. We hope the below information
                  will help you make an <br className="d-break-new" /> informed
                  decision about our compatibility with your needs.
                </p>
              </Col>
              <Col xs={12}>
                <Tabs defaultActiveKey="0">
                  {whyChoose.map((item: whyProps, index: number) => (
                    <Tab
                      eventKey={index.toString()}
                      title={item.title}
                      key={index.toString()}
                    >
                      <Row className="gap-4">
                        <Col lg={6}>
                          <div className="text-center text-lg-start h-md-100">
                            <Image
                              src={item.image}
                              width={item.width}
                              height={item.height}
                              priority={false}
                              alt={item.title}
                              style={{ objectFit: "cover" }}
                              className="img-fluid mb-md-32 h-md-100"
                            />
                          </div>
                        </Col>
                        <Col>
                          <h4 className="mb-4 mt-md-4 mt-lg-0">{item.title}</h4>
                          <p className="normal-text mb-5">{item.descr}</p>
                          {item.list.map(
                            (
                              item: whyListProps,
                              index: number,
                              arr: whyListProps[]
                            ) => (
                              <div key={index.toString()}>
                                <div className="single">
                                  <h5 className="mb-0">{item.title}</h5>
                                  <p className="choose-text mb-0 flex-1">
                                    {item.descr}
                                  </p>
                                </div>
                                {index !== arr.length - 1 && (
                                  <div className="line my-2-rem"></div>
                                )}
                              </div>
                            )
                          )}
                        </Col>
                      </Row>
                    </Tab>
                  ))}
                </Tabs>
              </Col>
            </Row>
          </Container>
        </div>
        <Testimonial />
        <div className="case">
          <Container>
            <Row>
              <Col md={7}>
                <h2 className="heading mb-5">
                  Explore our Industry or Case Studies and see if{" "}
                  <span>{`We're`} A Good Fit.</span>
                </h2>
                <Image
                  src="/assets/images/why.jpg"
                  width={3072}
                  height={2040}
                  priority={false}
                  alt="Why choose us"
                  style={{ objectFit: "contain" }}
                  className="img-fluid d-block d-md-none mb-32"
                />
              </Col>
              <Col xs={12}>
                <Tab.Container defaultActiveKey="0">
                  <Row>
                    <Col lg={3}>
                      <Nav variant="pills" className="flex-column">
                        {useCase.map(
                          ({ heading }: useCaseProps, index: number) => (
                            <Nav.Item key={index.toString()}>
                              <Nav.Link eventKey={index.toString()}>
                                {heading}
                              </Nav.Link>
                            </Nav.Item>
                          )
                        )}
                      </Nav>
                    </Col>
                    <Col lg={9}>
                      <Tab.Content>
                        {useCase.map((item: useCaseProps, index: number) => (
                          <UseCase
                            data={item}
                            tab={index.toString()}
                            key={index.toString()}
                          />
                        ))}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
            </Row>
          </Container>
        </div>
        <Contact type="fixed" title="Contact Us" />
      </div>
    </Layout>
  );
};

export default Home;
